import axiosInstance from './Api';

export default {

    async Login(payload) {
        const { email, password } = payload;

        let response = await axiosInstance().post(`/login`, payload);

        return response;
    },

    async StudentRegister(payload) {

        let response = await axiosInstance().post(`/student-register`, payload);

        return response;
    },

    async studentRegisterWithAgentLink(payload) {

        let response = await axiosInstance().post(`/agent-student-register/${payload.agent_user_id}`, payload);

        return response;
    },

    async studentRegisterWithScLink(payload) {

        let response = await axiosInstance().post(`/sc-student-register/${payload.sc_user_id}`, payload);

        return response;
    },

    async getRefreshToken(payload) {

        let response = await axiosInstance().post(`/refresh-token`, payload);

        return response;
    },

    async sendPasswordResetLink(payload) {

        let response = await axiosInstance().post(`/send-password-reset-link`, payload);

        return response;
    },

    async resetPassword(payload) {

        let response = await axiosInstance().post(`/reset-password`, payload);

        return response;
    },
}